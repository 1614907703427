// src/components/headerBar/hamburgerMenu/hamburgerMenu.js

import React from "react"
import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"
import BurgerIcon  from "../../../../assets/icons/burger.svg"
import CrossIcon from "../../../../assets/icons/cross.svg"
import styles from "./hamburgerMenu.css"

// font-size is the same like headlines h1
const classes =
  "font-light leading-loose tracking-normal text-6xl sm:text-6xlSM md:text-6xlMD md:leading-loose md:tracking-normal lg:text-6xlLG lg:leading-loose lg:tracking-normal xl:text-6xlXLs xl:leading-loose xl:tracking-normal"

  const ListLink = ({ url: to, label }) => (
  <li className="pl-2"><span className={classes}>
    {to !== undefined && <Link to={to}>{label}</Link>}
    {to === undefined && label}
    </span>
  </li>
)

class HamburgerMenuWrap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hidden: false,
    }
  }

  // showSettings(event) {
  //   event.preventDefault()
  // }

  render() {
    const { menuLinks, handleHamburgerMenuState } = this.props

    return (
      <Menu
        width="100%"
        right
        styles={styles}
        customBurgerIcon={<BurgerIcon />}
        customCrossIcon={<CrossIcon />}
        onStateChange={handleHamburgerMenuState}
      >
        <li className="outline-none">
          <Link to="/">
            <span className={classes}>
              Home
            </span>
          </Link>
        </li>
        {menuLinks.map((obj, index) => (
          <ListLink {...obj} key={index} />
        ))}

        {/* <a onClick={this.showSettings} className="menu-item--small" href="">
          Settings
        </a>  */}
      </Menu>
    )
  }
}

export default HamburgerMenuWrap
