// src/components/headerBar/headerBar.js

import React, {useState} from "react"
import { Link } from "gatsby"
import { useWindowWidth } from "@react-hook/window-size"
// import  isMobile from 'ismobilejs';
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import { getMainPages } from "../../utils/helper"
import HamburgerMenu from "./hamburgerMenu"

const LogoClaim = ({ isHamburgerMenuOpen }) => {
  const { name } = useSiteMetadata()
  const width = useWindowWidth()
  return (
    <div className="flex items-center h-full pl-1 ml-1/12 md:ml-1/24">
      <Link to="/">
        <h2
          className={`${
            isHamburgerMenuOpen ? "text-white" : "text-black"
          } relative md:font-semibold z-1200`}
        >
          <span className="pr-1 text-primary-500">
            {width < 600 ? "UX" : "User Experience"}
          </span>{" "}
          {/* <span className="font-light tracking-normal">|</span>{" "} */}
          <span className="font-normal tracking-normal">{name}</span>
        </h2>
      </Link>
    </div>
  )
}

const getHeaderbarAnimation = (overlabHeaderbar) => {
  let className

  // condition for index / homepage without animatin
  if (overlabHeaderbar === null) {
    className = "bg-transparent"
  }
  // condition for scroll on index / homepage
  else if (overlabHeaderbar === true) {
    className = "animate-fadebgTransparentToWhite"
  }
  // condition for every white page
  else {
    className = "animate-fadeBgWhitetoTransparent"
  }
  return className
}

const HeaderBar = ({ overlabHeaderbar }) => {
  const { pages } = useSiteMetadata()
  const [isHamburgerMenuOpen, setStatehamburgerMenIsOpen] = useState(false)

  const handleHamburgerMenuState =  (state) => setStatehamburgerMenIsOpen(state.isOpen)

  // TODO mobile phone orientation landscape keine fix header
  // const isPhone = isMobile.apple.phone || isMobile.android.phone
  // const isPhone = isMobile
  // console.log ('isSmartphone', isMobile)

  return (
    <div
      className={`${getHeaderbarAnimation(
        overlabHeaderbar
      )} fixed z-20 w-full h-12 px-3  md:h-14 lg:h-16`}
    >
      <div className="h-full max-w-screen-lg mx-auto ">
        <HamburgerMenu
          menuLinks={getMainPages(pages)}
          handleHamburgerMenuState={handleHamburgerMenuState}
        />
        {/* <span className="text-black">hello {overlabHeaderbar.toString()}</span> */}
        <LogoClaim isHamburgerMenuOpen={isHamburgerMenuOpen} />
      </div>
    </div>
  )
}

export default HeaderBar
